import { CorpBusinessAccount } from "redmond/apis/tysons/businesses";
import { Box, Theme, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import { ArrowDropDown } from "@material-ui/icons";
import {
  getRewardText,
  getTotalPriceText,
  Icon,
  IconName,
  twoDecimalFormatter,
} from "halifax";
import { CorpSessionInfo, CustomerAccountRole, RewardsAccount } from "redmond";
import { CorpAccountPickerModal } from "../CorpAccountPickerModal/component";

export interface MobileAccountSwitcherProps {
  accounts: CorpBusinessAccount[];
  sessionInfo: CorpSessionInfo;
}

const useStyles = makeStyles((theme: Theme) => ({
  banner: {
    width: "100%",
    backgroundColor: "#F4F4F4",
    padding: theme.spacing(2),
    borderWidth: 0,
  },
  rewardsBalance: {
    marginBottom: theme.spacing(1),
  },
  accountDetailSection: {
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.grey[300]}`,
    padding: theme.spacing(1),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  switchAccountSection: {
    display: "flex",
    alignItems: "center",
  },
}));

export const MobileAccountSwitcher: React.FC<MobileAccountSwitcherProps> = ({
  accounts,
  sessionInfo,
}) => {
  const classes = useStyles();
  const activeAccount =
    accounts.find(
      (account) =>
        account.optedIn?.businessId === sessionInfo.corporateInfo.businessId
    ) ?? accounts[0];
  const [isAccountDrawerOpen, setIsAccountDrawerOpen] = useState(false);

  const handleOpenDrawer = () => {
    setIsAccountDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setIsAccountDrawerOpen(false);
  };

  const getCardInfoText = (account: RewardsAccount) =>
    `${account.productDisplayName}...${account.lastFour}`;

  const renderRewardsBalance = () => (
    <Box
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: "10px",
        marginRight: "10px",
        marginLeft: "10px",
      }}
    >
      <Box>
        <Icon name={IconName.RewardsAccountBalanceIcon} />
      </Box>
      <Typography
        variant="body1"
        style={{
          marginLeft: "10px",
          lineHeight: 1,
        }}
      >
        Rewards:
        <>
          {" "}
          <span
            style={{
              color: "#008140",
              fontWeight: "bold",
            }}
          >
            {getRewardText({
              reward: activeAccount.rewardsAccount.rewardsBalance,
            })}
          </span>
          {activeAccount.rewardsAccount.rewardsBalance.currency !== "$" && (
            <>
              {" or "}
              <span
                style={{
                  color: "#008140",
                  fontWeight: "bold",
                }}
              >
                {getTotalPriceText({
                  price: activeAccount.rewardsAccount.rewardsCashEquivalent,
                  priceFormatter: twoDecimalFormatter,
                })}
              </span>
              {" for travel"}
            </>
          )}
        </>
      </Typography>
    </Box>
  );

  if (!activeAccount) {
    return null;
  }

  return (
    <>
      <Box className={classes.banner} component="button">
        {activeAccount.rewardsAccount.customerAccountRole ===
          CustomerAccountRole.Primary && renderRewardsBalance()}
        <Box
          className={classes.accountDetailSection}
          onClick={handleOpenDrawer}
        >
          <Typography variant="body1">
            {getCardInfoText(activeAccount.rewardsAccount)}
          </Typography>
          <Box className={classes.switchAccountSection}>
            <Typography
              style={{
                color: "#013D58",
                fontWeight: "bold",
              }}
            >
              Change account
            </Typography>
            <ArrowDropDown />
          </Box>
        </Box>
      </Box>
      <CorpAccountPickerModal
        open={isAccountDrawerOpen}
        onClose={handleCloseDrawer}
        accounts={accounts}
        sessionInfo={sessionInfo}
        title="Select Business Account"
        variant="switcher"
      />
    </>
  );
};
