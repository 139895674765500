import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  RouteComponentProps,
  Redirect,
  matchPath,
} from "react-router-dom";
import { datadogRum } from "@datadog/browser-rum";
import {
  StylesProvider,
  ThemeProvider,
  createGenerateClassName,
} from "@material-ui/core/styles";
import {
  CallState,
  CorpSessionInfo,
  CorporateInfo,
  PolicyTier,
  RewardsAccount,
  UserCorpPermissions,
} from "redmond";
import { CorpUserContext, getBusinessEligibleAccounts } from "@capone/common";
import {
  ExperimentsHookProvider,
  useExperimentsById,
} from "@capone/experiments";

import Body from "../components/Body";
import Header from "../components/capone-corporate/Header";
import Footer from "../components/Footer";
import { HomepageTakeover } from "../components/HomepageTakeover";
import { BasicAuth, ProtectedRoute, JWTAuth } from "../components/Auth";
import {
  PATH_HOME,
  HIDDEN_FOOTER_PATHS,
  HIDDEN_FOOTER_PATHS_MOBILE,
  HIDDEN_FOOTER_UNPROTECTED_PATHS,
  NO_FOOTER_PADDING_UNPROTECTED_PATHS,
  HIDDEN_HEADER_PATHS_DESKTOP,
  HIDDEN_HEADER_PATHS_MOBILE,
  HIDDEN_HEADER_UNPROTECTED_PATHS,
  UNPROTECTED_PATHS,
  DISPLAY_HOMEPAGE_TAKEOVER_PATHS,
  PATH_AUTH,
  NO_FOOTER_PADDING_PATHS,
  HIDDEN_BANNER_UNPROTECTED_PATHS,
} from "../utils/urlPaths";
import "./App.css";
import "../fonts.scss";
import { colors } from "../utils/capone-corporate/colors";
import { useWindowSize } from "../hooks/useWindowSize";
import { LoadScript } from "@react-google-maps/api";
import config from "../utils/capone-corporate/config";
import clsx from "clsx";
import AxiosInterceptors from "../components/AxiosInterceptors";
import {
  SESSION_BUSINESS_ID_KEY,
  SESSION_BUSINESS_NAME_KEY,
  SESSION_NUM_ELIGIBLE_BUSINESSES_KEY,
  SESSION_NUM_OPTEDIN_BUSINESSES_KEY,
  SESSION_USER_POLICY_TIER_KEY,
  SESSION_USER_ROLES_KEY,
  useDeviceTypes,
} from "halifax";
import ExperimentsProvider from "../context/experiments";
import UserSourceProvider from "../context/userSource";
import AuthModule from "../components/AuthModule";
import { RootBanner } from "../components/capone-corporate/RootBanner/component";
import { FunnelEntryTabs } from "../components/Header/components/FunnelEntryTabs";
import { corpTheme } from "./theme";

import Maintenance from "../components/Maintenance/maintenance";
import { fetchCanAccessConsumer } from "../api/v1/portal-access/fetchCanAccessConsumer";
import CorporateLogo from "../../src/assets/b2b/capone-corporate-logo.svg";
import DBCCorporateLogo from "../../src/assets/b2b/capone-corporate-logo-dbc.svg";
import { CAP_ONE_CORP_LOGO_ALT } from "../utils/constants";
import { fetchBusinessAccounts } from "../api/v1/multi-account/fetchBusinessAccounts";
import { CorpBusinessAccount } from "redmond/apis/tysons/businesses";
import { fetchRewardsAccounts } from "../api/v1/rewards/fetchRewardsAccounts";
import { MobileAccountSwitcher } from "../components/capone-corporate/MultiAccount/MobileAccountSwitcher/component";

const generateClassName = createGenerateClassName({
  productionPrefix: "ptBaseModule",
  seed: "ptBaseModule",
});

// TODO: add locale logic by integrating https://www.npmjs.com/package/locale-url-solver
const DEFAULT_LOCALE = "en";

export const StyleContext = createContext({});

const setTheme = () => {
  Object.keys(colors).forEach((key: string) => {
    document.body.style.setProperty(`--${key}`, (colors as any)[key]);
  });
};

const setViewWidthAndHeight = (width: string, height: string) => {
  document.body.style.setProperty(`--vw`, width);
  document.body.style.setProperty(`--vh`, height);
};

const App = () => {
  setTheme();

  const [sessionInfo, setSessionInfo] = useState<CorpSessionInfo>({
    userId: "",
    userInfo: { firstName: "", lastName: "", email: "" },
    isFirstSession: false,
    csrfToken: "",
    sessionExpiration: "",
    isDelegatedSession: "",
    corporateInfo: {
      businessId: "",
      permissions: {} as UserCorpPermissions,
      businessIsLive: false,
      role: [] as CorporateInfo["role"],
    },
  } as CorpSessionInfo);

  const [rewardAccounts, setRewardAccounts] = useState<RewardsAccount[]>([]);
  const [corpBusinessAccounts, setCorpBusinessAccounts] = useState<
    CorpBusinessAccount[]
  >([]);
  const [showMobileAccountSwitcher, setShowMobileAccountSwitcher] =
    useState(false);
  const [selectedRewardsAccountId, setSelectedRewardsAccountId] = useState<
    string | null
  >(null);
  const [canViewConsumer, setCanViewConsumer] = useState<boolean>(false);
  const [policies, setPolicies] = useState<PolicyTier>();
  const [fetchRewardsAccountsCallState, setFetchRewardsAccountsCallState] =
    useState<CallState>(CallState.NotCalled);
  const [launchEventSent, setLaunchEventSent] = useState(false);

  const portalSwitchingExperiment = useExperimentsById(
    "c1-marketplace-show-portal-switching-links"
  );

  const multiAccountExperiment =
    useExperimentsById("corp-multi-account-fe")?.variant === "available";

  const windowSize = useWindowSize();
  // Add a variable for vh to use for specifying full-screen height
  // 100vh does not work properly on iOS. https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
  setViewWidthAndHeight(
    `${windowSize.width * 0.01}px`,
    `${windowSize.height * 0.01}px`
  );

  const { matchesMobile } = useDeviceTypes();

  const updateSessionInfo = useCallback((sessionInfo: CorpSessionInfo) => {
    setSessionInfo(sessionInfo);
    datadogRum.setUser({ id: sessionInfo.userId });
    localStorage.setItem(
      SESSION_BUSINESS_ID_KEY,
      sessionInfo?.corporateInfo.businessId || ""
    );
    localStorage.setItem(
      SESSION_BUSINESS_NAME_KEY,
      sessionInfo?.corporateInfo.businessName || ""
    );
    localStorage.setItem(
      SESSION_USER_ROLES_KEY,
      sessionInfo.corporateInfo.role?.join(",")
    );
    localStorage.setItem(
      SESSION_USER_POLICY_TIER_KEY,
      sessionInfo?.corporateInfo.policyTier
    );
  }, []);

  const showDBCLogo =
    useExperimentsById("corp-custom-header-logo-dbc")?.variant === "available";

  const userContext = useMemo(
    () => ({
      sessionInfo,
      updateSessionInfo,
      policies,
      setPolicies,
      logo: {
        src: showDBCLogo ? DBCCorporateLogo : CorporateLogo,
        alt: CAP_ONE_CORP_LOGO_ALT,
      },
    }),
    [policies, sessionInfo, updateSessionInfo, showDBCLogo]
  );

  useEffect(() => {
    if (!launchEventSent && sessionInfo?.csrfToken) {
      setFetchRewardsAccountsCallState(CallState.InProcess);
      fetchBusinessAccounts().then((corpBusinessAccounts) => {
        const filteredAccounts = corpBusinessAccounts.filter((account) => {
          if (!account.optedIn) {
            return true;
          } else if (account.optedIn.isLive) {
            return account.optedIn.permissions.canBookTravel;
          } else {
            return account.optedIn.permissions.canViewAdmin;
          }
        });
        setShowMobileAccountSwitcher(
          Boolean(
            multiAccountExperiment &&
              filteredAccounts &&
              filteredAccounts.length > 1
          )
        );
        localStorage.setItem(
          SESSION_NUM_ELIGIBLE_BUSINESSES_KEY,
          filteredAccounts.length.toString() || "0"
        );
        localStorage.setItem(
          SESSION_NUM_OPTEDIN_BUSINESSES_KEY,
          filteredAccounts
            .filter((account) => "optedIn" in account)
            .length.toString() || "0"
        );
        setCorpBusinessAccounts(filteredAccounts);
      });
      fetchRewardsAccounts()
        .then((rewardsAccounts) => {
          setFetchRewardsAccountsCallState(CallState.Success);
          const businessEligibleAccounts = getBusinessEligibleAccounts(
            rewardsAccounts,
            sessionInfo
          );
          setRewardAccounts(businessEligibleAccounts);
          if (portalSwitchingExperiment?.variant === "available") {
            setCanViewConsumer(rewardAccounts.length > 1);
          }
        })
        .catch(() => setFetchRewardsAccountsCallState(CallState.Failed));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [launchEventSent, sessionInfo, multiAccountExperiment]);

  useEffect(() => {
    if (portalSwitchingExperiment?.variant === "oneportal") {
      fetchCanAccessConsumer().then((canAccessConsumer) => {
        setCanViewConsumer(canAccessConsumer);
      });
    }
  }, [portalSwitchingExperiment]);

  useEffect(() => {
    if (rewardAccounts.length) {
      setSelectedRewardsAccountId(rewardAccounts[0]?.accountReferenceId);
      localStorage.setItem(
        "referenceId",
        rewardAccounts[0]?.accountReferenceId
      );
    }
  }, [rewardAccounts]);

  useEffect(() => {
    if (rewardAccounts.length) {
      const highestEarnRewardsAccount = rewardAccounts.sort((prev, current) => {
        const earnMultiplier = (account: RewardsAccount) =>
          prev.earn.flightsMultiplier && current.earn.flightsMultiplier // should only use flightsMultiplier if prev/current has it or else diff values are compared
            ? account.earn.flightsMultiplier
            : account.earn.hotelsMultiplier ?? -1;
        return earnMultiplier(current) - earnMultiplier(prev);
      })[0];
      setSelectedRewardsAccountId(
        highestEarnRewardsAccount?.accountReferenceId
      );
      localStorage.setItem(
        "referenceId",
        highestEarnRewardsAccount?.accountReferenceId
      );
    }
  }, [rewardAccounts]);

  const maintenanceModeConfig = useExperimentsById("corp-traveler-maintenance");

  const isInMaintenance = (config: {
    isMaintenanceModeEnabled: any;
    isTimedMaintenanceModeEnabled: any;
    MAINTENANCE_START_TIME?: number;
    MAINTENANCE_END_TIME?: number;
  }) => {
    if (
      config.isTimedMaintenanceModeEnabled === "true" &&
      config.MAINTENANCE_START_TIME &&
      config.MAINTENANCE_END_TIME
    ) {
      const currentTime = Date.now();
      return (
        currentTime <= config.MAINTENANCE_END_TIME &&
        currentTime >= config.MAINTENANCE_START_TIME
      );
    }
    return config.isMaintenanceModeEnabled === "true";
  };

  if (isInMaintenance(config) || maintenanceModeConfig?.variant === "page") {
    return (
      <Maintenance
        title={maintenanceModeConfig?.data?.title || "Please try again soon"}
        subtext={
          maintenanceModeConfig?.data?.subtext ||
          "Service currently unavailable due to scheduled maintenance."
        }
      />
    );
  }

  return (
    <LoadScript googleMapsApiKey={config.googleMapsApiKey || ""}>
      <Router>
        <UserSourceProvider>
          <AxiosInterceptors />
          <StyleContext.Provider value={{ corpTheme }}>
            <StylesProvider generateClassName={generateClassName}>
              <ThemeProvider theme={corpTheme?.theme}>
                <JWTAuth>
                  <CorpUserContext.Provider value={userContext}>
                    <ExperimentsProvider isLoggedIn={!!sessionInfo.csrfToken}>
                      <ExperimentsHookProvider
                        isLoggedIn={!!sessionInfo.csrfToken}
                      >
                        <div
                          className={clsx("App-container", {
                            "corporate-custom-logo-dbc": showDBCLogo,
                          })}
                        >
                          <BasicAuth redirectUrl={PATH_HOME} />
                          <Switch>
                            {stripPathLeaf()}
                            <Route
                              path={PATH_AUTH}
                              render={(
                                browserRouterProps: RouteComponentProps
                              ) => (
                                <AuthModule
                                  language={DEFAULT_LOCALE}
                                  {...browserRouterProps}
                                />
                              )}
                            />
                            <Route path="*">
                              <ProtectedRoute
                                protectedUrl={PATH_HOME}
                                protectedContent={(pathname: string) => (
                                  <>
                                    <div
                                      className={clsx("App-content", "corp", {
                                        // TODO: verify on all URL paths (that do not render Footer) to see if any of
                                        // them should be included in NO_FOOTER_PADDING_PATHS
                                        "no-padding":
                                          NO_FOOTER_PADDING_PATHS.includes(
                                            pathname
                                          ),
                                        homepage:
                                          DISPLAY_HOMEPAGE_TAKEOVER_PATHS.includes(
                                            pathname
                                          ),
                                      })}
                                    >
                                      <Route
                                        render={(props) => {
                                          if (
                                            matchesMobile
                                              ? HIDDEN_HEADER_PATHS_MOBILE.includes(
                                                  props.location.pathname
                                                )
                                              : HIDDEN_HEADER_PATHS_DESKTOP.includes(
                                                  props.location.pathname
                                                )
                                          ) {
                                            return (
                                              <RootBanner
                                                canViewConsumer={
                                                  canViewConsumer
                                                }
                                                {...props}
                                              />
                                            );
                                          }
                                          return (
                                            <>
                                              <RootBanner
                                                canViewConsumer={
                                                  canViewConsumer
                                                }
                                                {...props}
                                              />
                                              <Header
                                                canViewConsumer={
                                                  canViewConsumer
                                                }
                                                isBusinessLive={Boolean(
                                                  sessionInfo.corporateInfo
                                                    .businessIsLive
                                                )}
                                                locationPath={pathname}
                                                language={DEFAULT_LOCALE}
                                                rewardsAccounts={rewardAccounts}
                                                selectedRewardsAccountId={
                                                  selectedRewardsAccountId
                                                }
                                                setSelectedRewardsAccountId={
                                                  setSelectedRewardsAccountId
                                                }
                                                corpBusinessAccounts={
                                                  corpBusinessAccounts
                                                }
                                              />
                                            </>
                                          );
                                        }}
                                      />
                                      <Route
                                        render={(props) => {
                                          if (
                                            !DISPLAY_HOMEPAGE_TAKEOVER_PATHS.includes(
                                              props.location.pathname
                                            )
                                          ) {
                                            return null;
                                          }
                                          if (matchesMobile) {
                                            return (
                                              <>
                                                {showMobileAccountSwitcher && (
                                                  <MobileAccountSwitcher
                                                    accounts={
                                                      corpBusinessAccounts
                                                    }
                                                    sessionInfo={sessionInfo}
                                                  />
                                                )}
                                                <HomepageTakeover
                                                  {...props}
                                                  rewardsAccounts={
                                                    rewardAccounts
                                                  }
                                                />
                                                <FunnelEntryTabs
                                                  {...props}
                                                  isMobile
                                                />
                                              </>
                                            );
                                          }
                                          return (
                                            <HomepageTakeover
                                              {...props}
                                              rewardsAccounts={rewardAccounts}
                                            />
                                          );
                                        }}
                                      />
                                      <Body
                                        language={DEFAULT_LOCALE}
                                        isFirstSession={
                                          sessionInfo.isFirstSession
                                        }
                                        launchEventSent={launchEventSent}
                                        setLaunchEventSent={setLaunchEventSent}
                                        className={clsx({
                                          //Only add top margin on paths with header
                                          "desktop-top-margin":
                                            !HIDDEN_HEADER_PATHS_DESKTOP.includes(
                                              pathname
                                            ) && !matchesMobile,
                                        })}
                                        rewardsAccounts={rewardAccounts}
                                        fetchRewardsAccountsCallState={
                                          fetchRewardsAccountsCallState
                                        }
                                        pathname={pathname}
                                      />
                                    </div>
                                    <Route
                                      render={(props) => {
                                        if (
                                          HIDDEN_FOOTER_PATHS.includes(
                                            props.location.pathname
                                          ) ||
                                          (matchesMobile &&
                                            HIDDEN_FOOTER_PATHS_MOBILE.includes(
                                              props.location.pathname
                                            ))
                                        ) {
                                          return null;
                                        }
                                        return (
                                          <Footer
                                            pathname={props.location.pathname}
                                          />
                                        );
                                      }}
                                    />
                                  </>
                                )}
                                unprotectedContent={(
                                  notAuthenticated: boolean
                                ) => {
                                  return (
                                    <>
                                      <Route
                                        path={UNPROTECTED_PATHS}
                                        render={(prop) => {
                                          return (
                                            <>
                                              <div
                                                className={clsx("App-content", {
                                                  "no-padding":
                                                    NO_FOOTER_PADDING_UNPROTECTED_PATHS.includes(
                                                      prop.location.pathname
                                                    ),
                                                  mobile: matchesMobile,
                                                })}
                                              >
                                                <RootBanner
                                                  canViewConsumer={
                                                    canViewConsumer
                                                  }
                                                  isHidden={
                                                    HIDDEN_BANNER_UNPROTECTED_PATHS.includes(
                                                      prop.location.pathname
                                                    ) || notAuthenticated
                                                  }
                                                  {...prop}
                                                />
                                                {!HIDDEN_HEADER_UNPROTECTED_PATHS.includes(
                                                  prop.location.pathname
                                                ) && (
                                                  <Header
                                                    canViewConsumer={
                                                      canViewConsumer
                                                    }
                                                    isBusinessLive={Boolean(
                                                      sessionInfo.corporateInfo
                                                        .businessIsLive
                                                    )}
                                                    locationPath={
                                                      prop.location.pathname
                                                    }
                                                    language={DEFAULT_LOCALE}
                                                    rewardsAccounts={
                                                      rewardAccounts
                                                    }
                                                    selectedRewardsAccountId={
                                                      selectedRewardsAccountId
                                                    }
                                                    setSelectedRewardsAccountId={
                                                      setSelectedRewardsAccountId
                                                    }
                                                    notAuthenticated={
                                                      notAuthenticated
                                                    }
                                                    corpBusinessAccounts={
                                                      corpBusinessAccounts
                                                    }
                                                  />
                                                )}
                                                <Body
                                                  language={DEFAULT_LOCALE}
                                                  isFirstSession={
                                                    sessionInfo.isFirstSession
                                                  }
                                                  launchEventSent={
                                                    launchEventSent
                                                  }
                                                  setLaunchEventSent={
                                                    setLaunchEventSent
                                                  }
                                                  className={clsx({
                                                    "desktop-top-margin":
                                                      !matchesMobile &&
                                                      !matchPath(
                                                        prop.location.pathname,
                                                        HIDDEN_HEADER_UNPROTECTED_PATHS
                                                      ),
                                                  })}
                                                  rewardsAccounts={
                                                    rewardAccounts
                                                  }
                                                  fetchRewardsAccountsCallState={
                                                    fetchRewardsAccountsCallState
                                                  }
                                                  pathname={
                                                    prop.location.pathname
                                                  }
                                                  notAuthenticated={
                                                    notAuthenticated
                                                  }
                                                />
                                              </div>
                                              {!HIDDEN_FOOTER_UNPROTECTED_PATHS.includes(
                                                prop.location.pathname
                                              ) && <Footer />}
                                            </>
                                          );
                                        }}
                                      ></Route>
                                    </>
                                  );
                                }}
                              />
                            </Route>
                          </Switch>
                        </div>
                      </ExperimentsHookProvider>
                    </ExperimentsProvider>
                  </CorpUserContext.Provider>
                </JWTAuth>
              </ThemeProvider>
            </StylesProvider>
          </StyleContext.Provider>
        </UserSourceProvider>
      </Router>
    </LoadScript>
  );
};

export default App;

const stripPathLeaf = () => {
  return (
    <>
      {/* note: standardize the path to have EXACTLY ONE trailing slash WITHOUT index.html suffix */}
      <Route
        exact
        strict
        path="/:url*//*"
        render={(props: RouteComponentProps) => {
          return (
            <Redirect
              to={{
                ...props.location,
                pathname: props.location.pathname.replace(/\/+$/, "") + "/",
              }}
            />
          );
        }}
      />
      <Route
        exact
        path="/:url*/index.html/"
        render={(props: RouteComponentProps) => {
          return (
            <Redirect
              to={{
                ...props.location,
                pathname: props.location.pathname.slice(
                  0,
                  -"index.html/".length
                ),
              }}
            />
          );
        }}
      />
      <Route
        exact
        path="/:url*/index.html"
        render={(props: RouteComponentProps) => {
          return (
            <Redirect
              to={{
                ...props.location,
                pathname: props.location.pathname.slice(
                  0,
                  -"index.html".length
                ),
              }}
            />
          );
        }}
      />
      <Route
        exact
        strict
        path="/:url*"
        render={(props: RouteComponentProps) => {
          return (
            <Redirect
              to={{
                ...props.location,
                pathname: props.location.pathname + "/",
              }}
            />
          );
        }}
      />
    </>
  ).props.children;
  // a solution so that Switch respects Route's from a Fragment; see https://github.com/ReactTraining/react-router/issues/5785
};
